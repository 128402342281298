import * as React from 'react';
import Data from '../../content/home';
import { SectionFaq } from '../components/sections/general';
import Seo from '../components/sections/general/seo';
import { SectionHero, SectionSluzby, SectionTv } from '../components/sections/home';
import Layout from '../layouts';

const { sections, seo } = Data;

export default () => {
    return (
        <Layout>
            <Seo {...seo} meta={[{name: "google-site-verification", content: "x6U9BSQBgd8aBu8AgLoG9DnNUzvBEpwCUdQu8JjiVMA"}]}/>
            <SectionHero
                heading={sections.hero.title}
                button={{
                    text: sections.hero.button.text,
                    link: sections.hero.button.link,
                }}
            >
                {sections.hero.subtitle}
            </SectionHero>
            <SectionSluzby heading={sections.internet.title} cards={sections.internet.cards} />
            <SectionTv heading={sections.tv.title} />
            <SectionFaq questions={sections.faq} />
        </Layout>
    );
};
