import styled from 'styled-components'
import { colors, styles } from '../../../../styles/constants'

const HeroContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;

    div {
        padding: 0 ${styles.st__frame_padding};
        z-index: 1;
        background-color: ${colors.clr_g};
        padding-top: 10rem;
    }
    svg {
        height: 500px;
        width: 100%;
        @media (max-width: 1200px) {
            height: 450px;
        }
    }

    @media (max-width: 450px) {
        svg {
            height: 400px;
        }
        div {
            padding-bottom: ${styles.st__frame_padding};
            padding-top: 6rem;

        }
    }
`

export {HeroContainer}