import { gsap } from 'gsap'
import * as React from 'react'
import { useLayoutEffect, useRef } from 'react'
import Section from '../../index'
import {CardWrapper, Card, Mouse} from './sluzby.style'
import {ScrollParams} from '../../../../animation/scroll'
import { SpeedParams } from '../../../../animation/speed'

export function SectionSluzby({heading, cards}) {
    const cardRef = useRef()
    const card = gsap.utils.selector(cardRef)

    useLayoutEffect(() => {
        gsap.timeline({scrollTrigger: {trigger: cardRef.current, ...ScrollParams} })
            .from(card('.card_heading'), {
                    opacity: 0,
                })
            .from(card('.card'),
                {
                    scale: 0.9,
                    opacity: 0,
                    duration: SpeedParams.duration - 0.2,
                    ease: 'slow'
                }
            )
            
        })

        return (
        <Section ref={cardRef}>
            <h2 className='card_heading'>{heading}</h2>
            <CardWrapper>
                {cards.map(({subtitle, cost, content, speed, link}, key)=>
                     <article className='card' key={key+link}>
                        <h3>{subtitle}</h3>
                        <Card to={link}>
                            <Mouse/>
                            <p className='cost'>{cost} kč</p>
                            {content}
                            <p><span>rychlost až:</span><span>{speed} Mbit/s</span></p>
                        </Card>
                    </article>
                )}
            </CardWrapper>
        </Section>
    )
}
