import { Link } from 'gatsby';
import * as React from 'react';
import Routes from '../routes.json';

export default [
    {
        question: 'Mám zájem o Vaše služby co mám dělat?',
        answer: (
            <p>
                Zavolejte nebo napište tel. číslo <a href="tel:727949114">727 949 114</a> nebo{' '}
                <a href="tel:723309410">723 309 410</a>, případně využijte náš formulář a my Vám co nejdříve zavolám zpět
            </p>
        ),
    },
    {
        question: 'Jak můžu za služby zaplatit?',
        answer: (
            <ul>
                <li>
                    hotově na <Link to={Routes.kontakt}>pobočce GreenNet</Link>
                </li>
                <li>
                    bezhotovostním převodem z účtu nezapomínejte u plateb uvádět svůj VS (variabliní symbol), který naleznete na
                    faktuře zaslané emailem
                </li>
            </ul>
        ),
    },
    {
        question: 'Mám problém s připojením k internetu.',
        answer: (
            <ol>
                <li>
                    Odpojte a znovu připojte k elektrickému napájení všechny prvky internetu (např.: WiFi router, anténu a
                    rozbočovač).
                </li>
                <li>
                    Zkontrolujte zapojení všech kabelů vedoucích do wifi routeru případně rozbočovače a ujistěte se, že
                    wifi routeru svítí. Většina modelů má na zadní straně přístroje vypínač, kterým lze v případě potřeby
                    wifi router vypnout.
                </li>
                <li>
                    Nikdy nemačkejte tlačítko “Reset”, pokud to situace vyžaduje. Tlačítkem “Reset” se přístroj uvádí do
                    výchozích hodnot a tato operace může zapříčinit nefunkčnost internetu.
                </li>
                <li>
                Pokud ani tak internet nefunguje, kontaktujte naše pracovníky na tel. <a href="tel:723309410">723 309 410</a>
                </li>
            </ol>
        ),
    },
];
