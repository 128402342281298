import { Link } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../../../../styles/constants'
//@ts-ignore: This code is fine
import MouseComponent from '../../../../assets/mouse-click.svg'
import { Card as DefaultCard } from '../../../card'

const CardWrapper = styled.span`
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
    row-gap: 2.5rem;
    justify-content: center;
    margin: auto;

    h3 {
        text-align: center;
    }

    article {
        transition: .25s transform;

        &:hover {
            transform: translateY(-2rem);
        }
    }
`

const Mouse = styled(MouseComponent)`
    position: absolute;
    top: 20px;
    left: 20px;
`

const Card = styled(Link)`
    ${DefaultCard};
    cursor: pointer;
    min-height: 350px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;

    p {
        padding: .25em 1.25em;

        &:last-of-type {
            margin-top: auto;
            display: flex;
            border-top: 1px solid ${colors.clr_w__15};
            justify-content: space-between;

            span:last-of-type {
                font-weight: bold;
            }
        }

        &.cost {
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 2rem;
            color: ${colors.clr_b};
            padding: .5em;
        }
    }

    @media (max-width: 1100px) {
       
    }
`


export {Mouse, CardWrapper, Card}