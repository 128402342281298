import * as React from 'react';
import { Important } from '../src/components/text';
import faq from './general/faq';
// @ts-ignore
import Routes from './routes.json';

export default {
    seo: {
        title: 'Spolehlivý internet pro každého',
        copyright: 'GreenNet s.r.o.',
        description: 'Rozsáhlá internetová vysokorychlostní síť na míru s rychlostí až 1000mbps se skvělou podporou, neustálým servisem, chytrou televizí s možností záznamu až dvou týdnů.'
    },
    sections: {
        hero: {
            title: 'Spolehlivý internet pro každého',
            subtitle: (
                <>
                    Odpoutejte se od limitů <Important>rychlosti</Important>
                    <br /> vašeho internetu
                </>
            ),
            button: {
                text: 'Objednat',
                link: Routes.objednat,
            },
        },
        internet: {
            title: 'Vyberte si z naší nabídky připojení a rychlostí',
            cards: [
                {
                    subtitle: 'Domácnosti',
                    link: Routes.internet + '#domacnost',
                    cost: 249,
                    content: (
                        <>
                            <p>Hledáte stavilní a rychlý internet pro Váš dům či zahradu ?</p>
                            <p>Prohlédněte si naši nabídku</p>
                        </>
                    ),
                    speed: '100',
                },
                {
                    subtitle: 'Firmy',
                    link: Routes.internet + '#firma',
                    cost: 500,
                    content: (
                        <>
                            <p>Garantovaný internet pro firmy se zaručenou kvalitou a dostupností</p>
                        </>
                    ),
                    speed: '1000',
                },
                {
                    subtitle: 'Bytové domy',
                    link: Routes.internet + '#bytovy-dum',
                    cost: 249,
                    content: (
                        <>
                            <p>Vysokorychlostní připojení pro bytové domy</p>
                            <p>Pokud nemáte kabelové rozvody, je možné je po dohodě vybudovat zcela zdarma</p>
                        </>
                    ),
                    speed: '1000',
                },
            ],
        },
        tv: {
            title: 'Televize dostupná vždy a všude',
            // link: "#",
            // button: "více o TV",
        },
        faq: faq
    },
};
