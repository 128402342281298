import { gsap } from 'gsap'
import * as React from 'react'
import { useLayoutEffect, useRef } from 'react'
//@ts-ignore: This code is fine
import SledovaniTv from '../../../../assets/sledovani-tv.svg'
import Button from '../../../button'
import { Tv, Card } from './tv.style'
import { ScrollParams } from '../../../../animation/scroll'
import { SpeedParams } from '../../../../animation/speed'

export function SectionTv ({heading}) {
    const tvRef = useRef()
    const tv = gsap.utils.selector(tvRef)

    useLayoutEffect(() => {
        gsap.timeline({scrollTrigger: {trigger: tvRef.current, ...ScrollParams}})
            .from(tv('.tv_heading'), {
                    opacity: 0
                })
            .from(tv('.tv_card'),
                {
                    scale: 0.9,
                    opacity: 0,
                    duration: SpeedParams.duration - 0.2,
                    ease: 'slow'
                }
            )
            .from(tv('.tv_button'),
                {
                    opacity: 0
                }
            )
    })
    return (
        <Tv ref={tvRef}>
            <h2 className='tv_heading'>{heading}</h2>
            <article>
                <Card as='div' style={{cursor:'default'}} className='tv_card'>
                    <SledovaniTv/>
                </Card>
                {/* <Button className='tv_button' animation='scale' size='sm' color='blue' href=''>
                    Více o TV
                </Button> */}
            </article>
        </Tv>
    )
}
