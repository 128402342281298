import * as React from 'react'
//@ts-ignore: This code is fine
import WavesSVG from '../../../../assets/waves--city-animation-white.svg'
import Button from '../../../button'
import { HeroContainer } from './hero.style'

export function SectionHero({children, heading, button}) {
    return (
        <HeroContainer>
            <div>
                <h1>{heading}</h1>
                <p>{children}</p>
                <Button color='blue' size='md' animation='scale' href={button.link}>{button.text}</Button>
            </div>
            <WavesSVG/>
        </HeroContainer>
    )
}