import { Link } from 'gatsby'
import styled from 'styled-components'
//@ts-ignore: This code is fine
import { styles } from '../../../../styles/constants'
import { Card as DefaultCard } from '../../../card'
import Section from '../../index'

const Tv = styled(Section)`
    text-align: center;
    display: grid;

    article {
        margin: auto auto ${styles.st__h2_indent};
    }
`

const Card = styled(Link)`
    ${DefaultCard};
    cursor: pointer;
    display: block;
    justify-content: center;
    max-width: 400px;
    padding: 70px 50px;
    margin-bottom: 20px;

    svg {
        width: 100%;
    }
`

export {Tv, Card}